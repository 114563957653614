import _ from 'lodash'
import React from 'react'
import {Tooltip} from 'antd'
import {useTranslation} from 'react-i18next'

import Tag from 'ca-common/icons/Tag.svg'

import {LIMITED_ACCESS} from 'ca-common/constants'

import {StyledTagsFilter, TagIcon} from './StyledTagsFilter'

const MAX_TAG_TEXT_LENGTH = 8

export const TagsFilter = (props: any) => {
    const handleChange = (tagIds: any) => props.onChange(tagIds)
    const filterTags = (inputValue: string, treeNode: {props: {key: string}}) =>
        _.includes(treeNode.props.key.toLowerCase(), inputValue.toLowerCase())

    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const {tags, tReady, ...passedProps} = props
    const {t} = useTranslation()
    const limitedAccessData = {
        text: t('forms:backupTags:limitedAccess'),
        id: LIMITED_ACCESS
    }
    const tagsData = [...tags, limitedAccessData]

    const treeData = tagsData.length
        ? tagsData.map(({text, id}: any) => ({
              title: (
                  <Tooltip title={text} placement="topLeft">
                      {text}
                  </Tooltip>
              ),
              value: id,
              key: text
          }))
        : []

    return (
        <>
            <TagIcon component={Tag} />

            <StyledTagsFilter
                allowClear
                treeCheckable
                mode="multiple"
                multiple
                showArrow
                treeData={treeData}
                showSearch
                filterTreeNode={filterTags}
                dropdownStyle={{maxHeight: '170px'}}
                onChange={handleChange}
                maxTagTextLength={MAX_TAG_TEXT_LENGTH}
                {...passedProps}
            />
        </>
    )
}
