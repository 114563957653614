import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, FormInstance} from 'antd'

import {SOURCE} from 'ca-common/common/enum'
import {INTEGRATION_TYPE} from 'src/ca-common/constants'

import {BackupHour} from 'src/newcore/features/BackupSettings/molecules/BackupHour'
import {BackupFrequency} from 'src/newcore/features/BackupSettings/molecules/BackupFrequency'
import {BackupNoteHeader} from 'src/newcore/features/BackupSettings/molecules/BackupFrequency/StyledBackupFrequency'
import {BACKUP_FREQUENCY} from 'src/ca-common/utils/datetime'
import {useOCAEnabled} from 'src/ca-common/features/Flags'
import {useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'

import {NotificationWrapper, Wrapper} from './StyledFrequencyAndHourSelectors'

type FrequencyAndHourSelectorsProps = {
    service: SOURCE
    form: FormInstance<Record<string, any>>
    isHighFrequencyBackupEnabled?: boolean
}

export const FrequencyAndHourSelectors = (props: FrequencyAndHourSelectorsProps) => {
    const {service, form, isHighFrequencyBackupEnabled} = props
    const {t} = useTranslation()
    const isOCAEnabled = useOCAEnabled()
    const backupFrequency = Form.useWatch(`${service}.backupFrequency`, form)
    const userInfo = useAppSelector(userInfoSelector)
    const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX

    return (
        <>
            <Wrapper>
                <BackupFrequency namePrefix={service} isHighFrequencyBackupEnabled={isHighFrequencyBackupEnabled} />
                <BackupHour namePrefix={service} backupFrequency={backupFrequency} />
            </Wrapper>
            {!isZix && isOCAEnabled && backupFrequency === BACKUP_FREQUENCY.DAILY3TIMES && (
                <NotificationWrapper flexCondition={service}>
                    <BackupNoteHeader>{t('backups:bulkActivation:notification:noteHeader')}</BackupNoteHeader>
                    {t('backups:bulkActivation:notification:headerTitle')}{' '}
                    {t('backups:bulkActivation:notification:alert')}
                </NotificationWrapper>
            )}
        </>
    )
}
