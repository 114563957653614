import React from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Spin} from 'antd'

import {MainTitle} from 'ca-common/ui-lib/atoms/Typography'

import {getFAQ} from 'src/newcore/redux/modules/faq'
import {FaqContentWrapper, FaqPageWrapper} from './StyledFaq'

export class CAFAQRaw extends React.Component {
    componentDidMount() {
        const {actions, match} = this.props

        actions.getFAQ({
            errorCode: `${match.params.source}#${match.params.id}`
        })
    }

    render() {
        const {faq} = this.props
        const title = _.get(faq, 'response.error', '')
        const description = atob(_.get(faq, 'response.description', ''))

        return (
            <Spin spinning={'fulfilled' !== faq.status}>
                <FaqPageWrapper>
                    <MainTitle>{title}</MainTitle>
                    <FaqContentWrapper dangerouslySetInnerHTML={{__html: description}} />
                </FaqPageWrapper>
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    faq: state.faq
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getFAQ
        },
        dispatch
    )
})

export const CAFaq = connect(mapStateToProps, mapDispatchToProps)(CAFAQRaw)
