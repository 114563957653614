import styled from 'styled-components'
import variables from 'ca-common/variables'

export const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${variables.gutterMd};
    padding-top: ${variables.gutterLg};
    border-top: 1px solid ${variables.blue};
    gap: ${variables.gutterXs};
`

export const FieldsWrapper = styled.div`
    .ant-form-item-control-input + div {
        font-size: ${variables.fontSizeSecondary};
    }

    .ant-form-item-label {
        width: 35%;
        text-align: left;
        white-space: break-spaces;

        align-self: baseline;
        min-height: ${variables.gutterLg};
    }

    .ant-row {
        align-items: center;
        width: 100%;
    }

    .ant-form-item-label {
        display: flex;
        align-items: center;
        height: auto;
    }

    .ant-form-item-label > label {
        height: auto;
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        align-self: baseline;
    }
`

export const Row = styled.div<{isSingleData?: boolean; noWidth?: boolean}>`
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    max-width: ${props => (props.isSingleData ? '50%' : props.noWidth ? 'none' : '100%')};

    & > div {
        flex: 1;
        padding-right: ${variables.gutterSm};
        padding-left: ${variables.gutterSm};
        align-items: baseline;
        display: flex;
        width: 100%;
    }

    @media screen and (max-width: ${variables.screenMd}) {
        flex-flow: column;
    }
`

export const RowWithOneItem = styled(Row)`
    max-width: 700px;

    @media screen and (max-width: ${variables.screenMd}) {
        .ant-form-item-label {
            width: 100%;
        }

        .ant-row {
            flex-direction: column;
            align-items: stretch;
            margin-bottom: ${variables.gutterMd};
        }
    }
`
export const Separator = styled.div`
    border-top: 1px solid ${variables.blue};
`
export const subscriptionButtonStyle = styled.span`
    margin-bottom: 15px;
`
