import React, {useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'
import {Form} from 'antd'
import {ACCESS_LEVEL, CREDENTIALS_TYPES, GENERAL, SERVICES, TASKS} from 'ca-common/constants'
import {parseFormPermissions, getServicesByWhiteList} from 'ca-common/utils/usersManagement'
import {User} from 'ca-common/ui-lib/components/UserManagement/User'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {useDynamics365Enabled} from 'ca-common/features/Flags'
import {SOURCE} from 'ca-common/common/enum'
import {UserInfoDataType} from 'src/ca-common/types'
import {addCredential, updateLocalCredentials} from 'src/newcore/redux/modules/userManagement'
import {useAppSelector, useAppDispatch} from 'src/newcore/components/Root'
import {permittedWhiteList} from 'src/newcore/utils/permissions'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'
import {UserPageForm} from './UserForm'

const USERS_PAGE = '/settings/users'

export const NewUserPage = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [submitting, setSubmitting] = useState(false)
    const userInfo = useAppSelector(userInfoSelector) as UserInfoDataType
    const companyInfo = useAppSelector(companyInfoSelector)
    const [selectedGeneralList, setSelectedGeneralList] = useState({})
    const [selectedTasksList, setSelectedTasksList] = useState({})
    const [selectedServiceList, setSelectedServiceList] = useState({})
    const [newUserForm] = Form.useForm<User>()
    const generalList = getGeneralList(companyInfo, userInfo)
    const tasksList = getTasksList()
    const isDynamics365Enabled = useDynamics365Enabled()
    const serviceListRaw = getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(userInfo?.permissions))
    const serviceList = useMemo(() => {
        if (isDynamics365Enabled) {
            return serviceListRaw
        }

        return {...serviceListRaw, sub: serviceListRaw.sub.filter((name: string) => name !== SOURCE.DYNAMICS365)}
    }, [isDynamics365Enabled, serviceListRaw])
    const ORIGINAL_LIST = [serviceList, generalList, tasksList]
    const handleSubmit = async (newUserValues: User) => {
        setSubmitting(true)
        const permissions: any = {}
        permissions[GENERAL] = selectedGeneralList
        permissions[SERVICES] = selectedServiceList
        permissions[TASKS] = selectedTasksList
        const values = {
            credentials: {
                type: newUserValues.type,
                email: newUserValues.email?.toLowerCase(),
                password: newUserValues.password,
                role: newUserValues.role
            },
            permissions: permissions
        }

        const data = {
            type: newUserValues.type,
            email: newUserValues.email?.toLowerCase(),
            password: newUserValues.password,
            permissions: parseFormPermissions(values, ORIGINAL_LIST)
        }

        try {
            const response = await dispatch(addCredential(data)).unwrap()
            setSubmitting(false)
            openSuccessNotification(t('userManagementToast:credentialAdded'))
            dispatch(updateLocalCredentials(response))
            history.push(USERS_PAGE)
        } catch (err: any) {
            setSubmitting(false)
            openErrorModal(`${t('userManagementToast:addFailed')} ${err.status}`)
        }
    }

    return (
        <UserPageForm
            handleSubmit={handleSubmit}
            credentialsData={{
                credentials: {
                    role: ACCESS_LEVEL.CUSTOM,
                    type: CREDENTIALS_TYPES.EMAIL
                }
            }}
            groups={{
                services: serviceList,
                tasks: tasksList,
                general: generalList
            }}
            disableOAuth={!companyInfo.isCloudally}
            isNew={true}
            submitting={submitting}
            form={newUserForm}
            setSelectedGeneralList={setSelectedGeneralList}
            setSelectedServiceList={setSelectedServiceList}
            setSelectedTasksList={setSelectedTasksList}
        />
    )
}
