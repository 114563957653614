import styled, {css} from 'styled-components'
import {Layout} from 'antd'
import Icon from '@ant-design/icons'
import {headerGradient} from './lib/constants'
import variables from 'ca-common/variables'
import {Alert} from 'ca-common/ui-lib/atoms/Alert'
import {Button} from 'ca-common/ui-lib/atoms'

const {Header} = Layout

export const StyledHeaderWrapper = styled(Header)`
    position: fixed;
    top: 0;
    width: 100%;
    height: ${variables.headerHeight};
    padding: 0;
    z-index: 11;
    background: none;
`

export const StyledHeader = styled.div`
    height: 100%;
    background: ${headerGradient};
    line-height: normal;
    display: flex;
    flex-flow: row;
    align-items: center;
    box-shadow: 0 2px 8px rgba(17, 35, 81, 0.06);
    position: relative;
`

export const StyledHeaderSeparator = styled.div<{isZix?: boolean}>`
    background-color: ${variables.navyBorder};
    width: 1px;
    height: ${variables.gutterMd};
    margin: 0 ${variables.gutterXs};

    @media screen and (min-width: ${variables.screenLg}) {
        margin: ${props =>
            props.isZix ? `0 ${variables.gutterXs}` : `0 ${variables.gutterSm} 0 ${variables.gutterXs}`};
    }
`

export const SensitiveAccountWrapper = styled.div`
    display: none;
    align-items: center;
    text-align: center;

    @media screen and (min-width: ${variables.screenLg}) {
        display: flex;
    }
`

export const SensitiveAccount = styled.span`
    cursor: default;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    color: ${variables.navyAsphalt};
    background: ${variables.lightGray};
    border-radius: ${variables.borderRadiusBase};
`

export const LogoWrapper = styled.div<{isZix?: boolean}>`
    min-width: ${variables.sidebarWidth};
    height: 100%;
    padding: ${props =>
        props.isZix
            ? `${variables.gutterXs} ${variables.gutterXs} ${variables.gutterXs} ${variables.gutterSm}`
            : `${variables.gutterXs} 0 ${variables.gutterXs} ${variables.gutterMd}`};
    line-height: normal;

    img {
        height: ${props => (props.isZix ? 'auto' : '34.32px')};
        max-height: 100%;
        max-width: ${props => (props.isZix ? '128px' : '100%')};
        width: ${props => (props.isZix ? '100%' : '137px')};
        display: block;
    }

    @media screen and (min-width: ${variables.screenLg}) {
        padding: ${props =>
            props.isZix
                ? `${variables.gutterXs} ${variables.gutterXs} ${variables.gutterXs} ${variables.gutterMd}`
                : `${variables.gutterXs} 0 ${variables.gutterXs} ${variables.gutterLg}`};
    }
`

export const StylesLogoLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export const StyledPanel = styled.div`
    padding: ${variables.gutterXs} ${variables.gutterSm} ${variables.gutterXs} 0;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: space-between;
    gap: ${variables.gutterSm};

    > a {
        // Zix Back Button
        margin-left: 90px;
    }

    @media screen and (min-width: ${variables.screenLg}) {
        padding: ${variables.gutterXs} ${variables.gutterMd} ${variables.gutterXs} 0;
    }
`

export const StyledHeaderTitle = styled.div<{isZix?: boolean}>`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    ${StyledHeaderSeparator} {
        margin-left: ${props => props.isZix && '0'};
    }
    justify-self: flex-start;
    p {
        font-family: ${variables.publicPageFont};
        color: ${variables.white};
        margin: 0;
        display: ruby;
    }
`
export const StyledTitleAndVersion = styled.div<{isTitleVersion?: boolean; isZix?: boolean}>`
    font-size: ${props => (props.isTitleVersion ? '10px' : '19px')};
    font-weight: 400;
    display: flex;
    line-height: 1;
    white-space: nowrap;
    position: relative;

    margin: ${props => (props.isTitleVersion ? `7px 0 0 ${variables.gutterXs}` : '0')};

    @media screen and (min-width: ${variables.screenMd}) {
        font-size: ${props =>
            props.isZix && props.isTitleVersion ? '15px' : props.isTitleVersion ? variables.fontSizeSecondary : '21px'};
    }
`

export const StyledActions = styled.div<{withNotification: boolean}>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-left: auto;
    max-width: 100%;
    gap: 18px;

    @media screen and (min-width: ${variables.screenSm}) {
        max-width: calc(
            100vw - ${({withNotification}) => (withNotification ? '450px' : '260px')}
        ); // 260px = Logo + Partner Portal + gap width on PP, 450px = with smallest possible notification
    }
`

export const StyledLogout = styled.a`
    display: flex;
    min-width: 18px;
    align-items: center;
    font-size: ${variables.fontSizeBase};
    font-weight: 400;
    line-height: normal;
    color: ${variables.white};
    text-decoration: none;

    @media screen and (min-width: ${variables.screenLg}) {
        min-width: 81px;
    }

    span:first-child {
        display: none;
        margin-right: ${variables.gutterXs};

        @media screen and (min-width: ${variables.screenLg}) {
            display: inline;
        }
    }

    .anticon {
        font-size: 1.5em;
        top: 5px;
        left: 10px;
        color: ${variables.white};
    }
    &:hover {
        color: ${variables.iceBlue};
        .anticon {
            color: ${variables.iceBlue};
        }
    }
`

export const AlertContainer = styled.div`
    display: none;
    flex-basis: 222px;
    flex-shrink: 1;
    min-width: 222px;
    padding-left: ${variables.gutterSm};

    @media screen and (min-width: ${variables.screenMd}) {
        display: block;
        min-width: 388px;
        flex-basis: 455px;
    }

    @media screen and (min-width: ${variables.screenXl}) {
        flex-basis: 750px;
        min-width: 455px;
    }
`

export const StyledAlert = styled(Alert)`
    h4 {
        display: none;

        @media screen and (min-width: ${variables.screenXl}) {
            display: block;
        }
    }

    span:nth-child(2) {
        display: none;

        @media screen and (min-width: ${variables.screenLg}) {
            display: block;
        }
    }

    div > span:nth-child(2) {
        display: none;

        @media screen and (min-width: ${variables.screenDesktop}) {
            display: block;
        }
    }

    span[aria-label='exclamation-circle'] {
        display: none;

        @media screen and (min-width: ${variables.screenLg}) {
            display: block;
        }
    }
`

export const StyledHelpButton = styled(Button)`
    background: transparent;
    border-color: ${variables.white};

    &:hover,
    &:active,
    &:focus {
        background: ${variables.brandDarkHover};
        border-color: ${variables.white};
    }

    svg {
        font-size: 18px;
    }
`

export const UsernameWrapper = styled.span`
    .ant-btn:hover {
        background-color: ${variables.brandDarkHover} !important;
    }

    & > button > div .ant-tooltip {
        max-width: 1000px;
        font-family: ${variables.publicPageFont};
        top: -12px !important;
        padding: 0;

        .ant-tooltip-content {
            min-width: 120px;
        }

        .ant-tooltip-inner {
            padding: 0 ${variables.gutterXs};
            font-size: ${variables.fontSizeSecondary};
            min-height: ${variables.gutterMd};
            text-align: center;
            line-height: 24px;
        }

        .ant-tooltip-arrow {
            display: none;
        }
    }
`
export const StyledIcon = styled(Icon)`
    width: ${variables.borderRadiusBase};
    height: ${variables.gutterMd};
    position: relative;
    font-size: ${variables.gutterLg};
    margin-left: 2%;
`
export const BackButtonLink = styled(Button)`
    padding-right: 2%;
    line-height: 30px !important;
    left: ${variables.gutterXs};
    margin-right: ${variables.borderRadiusBase};
`
