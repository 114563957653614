import {SOURCE, STORAGE_TYPE} from 'ca-common/common/enum'
import {ACTIVITY_STATUS, SYSTEM_AUDIT_ACTIVITY, ActivityRecord} from 'ca-common/features/ActivityInfinityTable'

export const isDownloadButton = (link: string, record: ActivityRecord): boolean => {
    const {activity, activityStatus, description, source, isExpired} = record

    switch (activityStatus) {
        case ACTIVITY_STATUS.FAILED:
            return source === SOURCE.SFC && activity === SYSTEM_AUDIT_ACTIVITY.RESTORE && Boolean(link)
        case ACTIVITY_STATUS.CANCELLED:
            return false
    }

    switch (activity) {
        case SYSTEM_AUDIT_ACTIVITY.EXPORT:
            return description.exportDestination === STORAGE_TYPE.CLOUDALLY_S3
        case SYSTEM_AUDIT_ACTIVITY.RESTORE:
        case SYSTEM_AUDIT_ACTIVITY.COMPARE:
            return (
                source === SOURCE.SFC ||
                (source === (SOURCE.SHAREPOINT_MULTI || SOURCE.SHAREPOINT || SOURCE.ONEDRIVE) &&
                    activityStatus === ACTIVITY_STATUS.EXCEPTION &&
                    (Boolean(link) || isExpired != undefined))
            )
        default:
            return false
    }
}
