import React from 'react'
import {reduce, map, reject, pick} from 'lodash'
import {useTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import queryString from 'query-string'

import {SelectedFiltersBar} from 'ca-common/ui-lib/organisms/SelectedFiltersBar/SelectedFiltersBar'
import {getStatusIcon} from 'ca-common/utils/taskStatus'
import Bookmark from 'ca-common/icons/Bookmark.svg'

import {StyledSelectedFilters, StyledIcon} from './StyledSelectedFilters'

const ARRAY_FORMAT = {arrayFormat: 'bracket'}

export const SelectedFilters = withRouter(props => {
    const getURLQuery = () => {
        const search = props?.location?.search || {}

        return queryString.parse(search, ARRAY_FORMAT)
    }

    const {values = pick(getURLQuery(), ['statusFilter', 'licenseStatus', 'tagIds']), fetched, tags} = props
    const {t} = useTranslation()

    const setURLSearch = params => {
        const {history} = props

        const newParams = {
            ...getURLQuery(),
            ...params
        }

        history.push({
            search: queryString.stringify(newParams, ARRAY_FORMAT)
        })
    }

    const remove = value => {
        const [parent, target] = value.split('.')

        const newValues = reduce(
            values,
            (result, current, key) => {
                let newValue
                if (Array.isArray(current)) {
                    if (target === undefined) {
                        // for tags
                        newValue = values.tagIds.includes(parent) ? reject(current, i => i === parent) : current
                    } else {
                        // status
                        newValue = parent === key ? reject(current, i => i === target) : current
                    }
                } else {
                    // license
                    newValue = parent === key ? undefined : current
                }

                return {
                    ...result,
                    [key]: newValue
                }
            },
            {}
        )

        setURLSearch(newValues)
    }

    const removeAll = () => {
        const newValues = reduce(values, (result, current, key) => ({...result, [key]: []}), {})

        setURLSearch(newValues)
    }

    const prepareTagData = () => {
        // selected tags from tag data
        const selectedTag = tags.filter(value => values.tagIds?.includes(value.id))
        const newTagData = selectedTag.reduce((value, item) => {
            value.push({
                value: `${item.id}`,
                label: `${item.text}`,
                icon: <StyledIcon component={Bookmark} />
            })
            return value
        }, [])
        return newTagData
    }

    const prepareData = data => {
        return reduce(
            data,
            (result, current, key) => {
                let newData = []
                if (key !== 'tagIds') {
                    if (Array.isArray(current)) {
                        newData = map(current, item => ({
                            value: `${key}.${item}`,
                            label: t(`backups:newTaskStatus:${item}`),
                            icon: <StyledIcon component={getStatusIcon(item)} />
                        }))
                    }
                    if (typeof current === 'string') {
                        newData = [
                            {
                                value: `${key}.${current}`,
                                label: t(`backups:${key}:${current}`),
                                icon: null
                            }
                        ]
                    }
                }

                return [...result, ...newData]
            },
            []
        )
    }

    const selectedData = () => {
        return [...prepareData(values), ...prepareTagData()]
    }

    return (
        <StyledSelectedFilters disabled={fetched}>
            <SelectedFiltersBar selectedFilterValues={selectedData()} onRemove={remove} onRemoveAll={removeAll} />
        </StyledSelectedFilters>
    )
})
