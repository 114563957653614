import React, {useEffect} from 'react'
import {matchPath, useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {Footer} from 'ca-common/components/PublicSite/MarketingFooter/Footer'
import {SOCIAL_NETWORK_LINKS, PUBLIC_PAGES, APP_DEFAULT_PAGE} from 'ca-common/constants'

import {companyInfoSelector, getPublicCompanyInfo} from 'src/newcore/redux/modules/companyInfo'
import {useAppDispatch, useAppSelector} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {isExternal} from 'src/newcore/utils/companyInfo'
import {SecureCloudBackup, Awards, Customers} from 'src/newcore/features/SignUp/molecules'
import {Header} from 'src/newcore/features/SignUp/organisms'

import {ContentWrapper} from './StyledSignUpWrapper'

export const SignUpWrapper = (props: React.PropsWithChildren<Record<string, never>>) => {
    const {children} = props
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector(userInfoSelector)
    const companyInfo = useAppSelector(companyInfoSelector)
    const history = useHistory()
    const {t} = useTranslation()

    useEffect(() => {
        const match = matchPath(history.location.pathname, {
            path: values(PUBLIC_PAGES)
        })

        if (!isEmpty(userInfo) && match) {
            history.push(`/${APP_DEFAULT_PAGE}`)
        }
    }, [])

    useEffect(() => {
        if (companyInfo.status !== 'fulfilled') {
            dispatch(getPublicCompanyInfo())
        }
    }, [])

    return companyInfo.status === 'fulfilled' ? (
        <>
            <Header
                isExternalUserManagement={isExternal()}
                logoSrc={companyInfo.logo280x51}
                name={companyInfo.name}
                title={t('header:portalTitle')}
                titleVersion={t('header:portalTitleVersion')}
                isWL={companyInfo.whiteLable || companyInfo.isWhiteLable}
            />
            <ContentWrapper>
                {children}
                <SecureCloudBackup serviceWhitelist={companyInfo.serviceWhitelist} />
                {companyInfo.isCloudally && (
                    <>
                        <Awards />
                        <Customers />
                        <Footer socialNetworks={SOCIAL_NETWORK_LINKS} />
                    </>
                )}
            </ContentWrapper>
        </>
    ) : (
        <Spinner modifier="page" />
    )
}
