import React, {useEffect, useState, useMemo} from 'react'
import {useHistory, useParams} from 'react-router'
import {useTranslation} from 'react-i18next'
import {Form} from 'antd'
import {User} from 'ca-common/ui-lib/components/UserManagement/User'
import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {
    parseExistPermission,
    getRole,
    parseFormPermissions,
    getServicesByWhiteList
} from 'ca-common/utils/usersManagement'
import {GENERAL, SERVICES, TASKS} from 'src/ca-common/constants'
import {UserInfoDataType} from 'ca-common/types'
import {openSuccessNotification} from 'ca-common/utils/toasts'
import {openErrorModal} from 'ca-common/utils/modals'
import {SOURCE} from 'ca-common/common/enum'
import {useDynamics365Enabled} from 'ca-common/features/Flags'
import {getPermissions, setPermissions, fetchCredentials} from 'src/newcore/redux/modules/userManagement'
import {permittedWhiteList, shrinkPermissionsByServiceWhiteList} from 'src/newcore/utils/permissions'
import {useAppSelector, AppState, useAppDispatch} from 'src/newcore/components/Root'
import {userInfoSelector} from 'src/newcore/redux/modules/account'
import {companyInfoSelector} from 'src/newcore/redux/modules/companyInfo'
import {UserPageForm} from './UserForm'
import {getGeneralList, getTasksList, SERVICES_LIST} from './Constants'

const USERS_PAGE = '/settings/users'

export const EditUser = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector(userInfoSelector) as UserInfoDataType
    const companyInfo = useAppSelector(companyInfoSelector)
    const credentials = useAppSelector((state: AppState) => state.credentials)
    const permission = useAppSelector((state: AppState) => state.permission)
    const [submitting, setSubmitting] = useState(false)
    const [stateFilled, setstateFilled] = useState(false)
    const [selectedGeneralList, setSelectedGeneralList] = useState({})
    const [selectedTasksList, setSelectedTasksList] = useState({})
    const [selectedServiceList, setSelectedServiceList] = useState({})
    const [userPermission, setUserPermission] = useState({})
    const isDynamics365Enabled = useDynamics365Enabled()

    const serviceListRaw = getServicesByWhiteList(SERVICES_LIST, permittedWhiteList(userInfo?.permissions))

    const serviceList = useMemo(() => {
        if (isDynamics365Enabled) {
            return serviceListRaw
        }

        return {...serviceListRaw, sub: serviceListRaw.sub.filter((name: string) => name !== SOURCE.DYNAMICS365)}
    }, [isDynamics365Enabled, serviceListRaw])
    const [newUserForm] = Form.useForm<User>()
    const history = useHistory()
    const {id} = useParams<{id: string}>()
    useEffect(() => {
        if (credentials.status !== 'fulfilled') {
            dispatch(fetchCredentials({}))
        }
        dispatch(getPermissions({id}))
            .then(response => {
                setUserPermission(shrinkPermissionsByServiceWhiteList(response.payload))

                setstateFilled(true)
            })
            .catch(data => {
                if (!data.success) {
                    openErrorModal(data.status)
                }
            })
    }, [])

    const isDataLoaded = (stateFilled: boolean, permission: any, credentials: any) => {
        return stateFilled && [permission.status, credentials.status].every(status => status === 'fulfilled')
    }
    if (!isDataLoaded(stateFilled, permission, credentials)) {
        return <Spinner modifier="page" />
    }
    const getCurrentCredential = (credentials: any, id: string) => {
        return credentials.response.data.find((cred: any) => cred.id === id)
    }

    const currentCredential: any = getCurrentCredential(credentials, id)
    const generalList = getGeneralList(companyInfo, userInfo)
    const tasksList = getTasksList()
    const ORIGINAL_LIST = [serviceList, generalList, tasksList]
    const submitForm = async (editUserData: User) => {
        setSubmitting(true)
        const permissions: any = {}
        permissions[GENERAL] = selectedGeneralList == undefined ? {} : selectedGeneralList
        permissions[SERVICES] = selectedServiceList == undefined ? {} : selectedServiceList
        permissions[TASKS] = selectedTasksList == undefined ? {} : selectedTasksList
        const values = {
            credentials: {
                type: editUserData.type,
                email: editUserData.email?.toLowerCase(),
                role: editUserData.role
            },
            permissions: permissions
        }
        const data = {
            type: editUserData.type,
            email: editUserData.email.toLowerCase(),
            permissions: parseFormPermissions(values, ORIGINAL_LIST)
        }

        try {
            await dispatch(setPermissions(data)).unwrap()
            openSuccessNotification(t('userManagementToast:permissionsUpdated'))
            history.push(USERS_PAGE)
            setSubmitting(false)
        } catch (err: any) {
            setSubmitting(false)
            return openErrorModal(err.status)
        }
    }

    return (
        <UserPageForm
            handleSubmit={submitForm}
            credentialsData={{
                permissions: parseExistPermission(userPermission, ORIGINAL_LIST),
                credentials: {
                    ...currentCredential,
                    role: getRole(permission.response?.accessLevel),
                    type: currentCredential.type || ''
                }
            }}
            groups={{
                services: serviceList,
                tasks: tasksList,
                general: generalList
            }}
            disableOAuth={!companyInfo.isCloudally}
            isNew={false}
            submitting={submitting}
            form={newUserForm}
            setSelectedGeneralList={setSelectedGeneralList}
            setSelectedServiceList={setSelectedServiceList}
            setSelectedTasksList={setSelectedTasksList}
        />
    )
}
