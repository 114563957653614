import React from 'react'
import {useTranslation} from 'react-i18next'
import {Form, Input, Select} from 'antd'
import {createRules} from 'ca-common/utils/form'
import {requiredValidate, emailValidate, passwordValidate} from 'ca-common/utils/validation'
import {CREDENTIAL_TYPE_SELECT, ROLE_OPTIONS, CREDENTIALS_TYPES} from 'ca-common/constants'
import {FieldsWrapper, Row} from 'src/ca-common/ui-lib/common/StyledForm'
import {User} from './User'

type UserDetailsprops = {
    isPP: boolean
    isNew: boolean
    disableOAuth?: boolean
    isEmail: boolean
    isEmailTypeSelected: boolean
}

export const UserDetail = (props: UserDetailsprops) => {
    const {t} = useTranslation()
    const {isPP, isNew, disableOAuth, isEmail, isEmailTypeSelected} = props
    const credentialTypeOptions = isPP
        ? CREDENTIAL_TYPE_SELECT.filter(cType => cType.value !== CREDENTIALS_TYPES.OKTA)
        : CREDENTIAL_TYPE_SELECT

    return (
        <FieldsWrapper>
            <Row>
                <Form.Item<User>
                    name="email"
                    rules={createRules([requiredValidate, emailValidate])}
                    label={t('userManagement:email')}
                    required
                >
                    <Input disabled={!isNew} />
                </Form.Item>
                <Form.Item<User> name="type" rules={createRules([requiredValidate])} label={t('userManagement:type')}>
                    <Select
                        placeholder={t('userManagement:typePlaceholder')}
                        options={credentialTypeOptions}
                        showSearch={false}
                        allowClear={false}
                        disabled={!isNew || disableOAuth}
                    />
                </Form.Item>
            </Row>
            <Row
                isSingleData={!isNew || !isEmailTypeSelected}
                style={{marginLeft: !isNew || !isEmailTypeSelected ? '49%' : undefined}}
            >
                {isEmail && isNew && isEmailTypeSelected && (
                    <Form.Item<User>
                        name="password"
                        rules={createRules([requiredValidate, passwordValidate])}
                        label={t('userManagement:password')}
                        required
                    >
                        <Input type="password" />
                    </Form.Item>
                )}
                <Form.Item<User> name="role" label={t('userManagement:role')}>
                    <Select options={ROLE_OPTIONS} placeholder={t('userManagement:rolePlaceholder')} />
                </Form.Item>
            </Row>
        </FieldsWrapper>
    )
}
