import React, {Component} from 'react'
import {Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from '@reduxjs/toolkit'
import {Switch} from 'react-router'
import {NotFound} from 'ca-common/components/NotFound'

import {INTEGRATION_TYPE, PAGES} from 'ca-common/constants'
import {isExternal} from 'src/newcore/utils/companyInfo'
import {isPrimary} from 'src/newcore/components/Settings/Utils'
import {clearCredentials} from 'src/newcore/redux/modules/userManagement'

import {NewUserPage, EditUser, UsersPage} from 'src/newcore/components/Settings/Pages/Users'

import './Users.scss'

class UsersRoutingRaw extends Component {
    componentDidMount = () => {
        const {history, userInfo} = this.props

        const isZix = userInfo.integrationType === INTEGRATION_TYPE.ZIX

        if (isExternal() && !isPrimary(userInfo.userAccountType) && !isZix) {
            history.push(`/${PAGES.SETTINGS}`)
        }
    }

    componentWillUnmount = () => {
        this.props.actions.clearCredentials()
    }

    render = () => {
        const {match} = this.props

        return (
            <Switch>
                <Route path={`${match.url}/`} exact component={UsersPage} />
                <Route path={`${match.url}/new`} exact component={NewUserPage} />
                <Route path={`${match.url}/:id`} exact component={EditUser} />
                <Route component={NotFound} />
            </Switch>
        )
    }
}

const mapStateToProps = state => ({
    userInfo: {
        status: state.userInfo.status,
        ...state.userInfo.response
    }
})

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                clearCredentials
            },
            dispatch
        )
    }
})

export const UsersRouting = connect(mapStateToProps, mapDispatchToProps)(UsersRoutingRaw)
