import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import type {TreeProps} from 'antd/es/tree'
import {Form, FormInstance, Col, Button} from 'antd'
import {
    CREDENTIALS_TYPES,
    ACCESS_LEVEL,
    RESTORE_AND_DOWNLOAD,
    DELETEITEMS,
    SUB,
    ALL,
    PREVIEW,
    RESTORE,
    DOWNLOAD
} from 'ca-common/constants'
import {Jumbotron} from 'ca-common/ui-lib/components/Jumbotron'
import {JumbotronToggle} from 'ca-common/ui-lib/molecules/JumbotronToggle'
import {UserDetail} from 'ca-common/ui-lib/components/UserManagement/UserDetail'
import {Row, FormFooter} from 'src/ca-common/ui-lib/common/StyledForm'
import {User} from 'ca-common/ui-lib/components/UserManagement/User'
import {TreeCheckbox} from 'ca-common/ui-lib/TreeCheckBox/TreeCheckBox'

type UserFormprops = {
    disableOAuth: boolean
    credentialsData: any
    groups: any
    handleSubmit: (data: User) => void
    isNew: boolean
    submitting: boolean
    form: FormInstance<User>
    setSelectedGeneralList: React.Dispatch<React.SetStateAction<any>>
    setSelectedTasksList: React.Dispatch<React.SetStateAction<any>>
    setSelectedServiceList: React.Dispatch<React.SetStateAction<any>>
}
export const UserPageForm = (props: UserFormprops) => {
    const {
        disableOAuth,
        credentialsData,
        groups,
        handleSubmit,
        isNew,
        submitting,
        form,
        setSelectedGeneralList,
        setSelectedServiceList,
        setSelectedTasksList
    } = props
    const {t} = useTranslation()
    const history = useHistory()
    const [isServicesSelected, setIsServicesSelected] = useState(false)
    const [isBackupTaskSelectedSelected, setIsBackupTaskSelectedSelected] = useState(false)
    const [checkedGeneralPermissions, setCheckedGeneralPermissions] = useState({})
    const [checkedTasksPermissions, setCheckedTasksPermissions] = useState({})
    const [checkedServicesPermissions, setCheckedServicesPermissions] = useState({})
    const [isFormTouched, setIsFormTouched] = useState(false)
    const [isEmailTypeSelected, setIsEmailTypeSelected] = useState(true)
    const permissionsData = credentialsData?.permissions
    const checkedSelectedPermissions = (permissions: any) => {
        const checkedPermissions: any[] = []
        if (permissions) {
            Object.keys(permissions.sub).forEach(permission => {
                checkedPermissions.push(permission)

                if (permission === PREVIEW || permission === RESTORE || permission === DOWNLOAD) {
                    setIsBackupTaskSelectedSelected(true)
                }
            })
            if (permissions[ALL]) {
                checkedPermissions.push(ALL)
            }
        }
        return checkedPermissions
    }

    useEffect(() => {
        if (permissionsData?.services) {
            if (Object.keys(credentialsData.permissions.services.sub).length > 0) {
                setIsServicesSelected(true)
            }
        }
        if (!isNew) {
            setSelectedGeneralList(permissionsData?.general)
            setSelectedTasksList(permissionsData?.tasks)
            setSelectedServiceList(permissionsData?.services)
            setCheckedGeneralPermissions(checkedSelectedPermissions(permissionsData?.general))
            setCheckedServicesPermissions(checkedSelectedPermissions(permissionsData?.services))
            setCheckedTasksPermissions(checkedSelectedPermissions(permissionsData?.tasks))
        }
    }, [])

    const onFinish = (values: User) => {
        if (form.isFieldsTouched() || !isNew) {
            handleSubmit(values)
        }
    }
    const initialValues = {
        type: credentialsData.credentials.type,
        role: credentialsData.credentials.role,
        email: credentialsData.credentials.email
    }

    const getSelectedOperationsList = (nodeInfo: any, checkedPermissions: string[]) => {
        const selectedSubList: any = {}
        let isAllSelected = false
        const selectedGeneralList: any = {}
        setIsFormTouched(true)
        nodeInfo.checkedNodes.forEach((generalOperation: any) => {
            const generalOperationName = generalOperation.key

            checkedPermissions.push(generalOperationName)

            if (generalOperationName == ALL) {
                isAllSelected = true
            } else {
                if (
                    generalOperationName === PREVIEW ||
                    generalOperationName === RESTORE ||
                    generalOperationName === DOWNLOAD
                ) {
                    setIsBackupTaskSelectedSelected(true)
                }
                selectedSubList[generalOperationName] = true
            }
        })
        selectedGeneralList[SUB] = selectedSubList
        selectedGeneralList[ALL] = isAllSelected
        return selectedGeneralList
    }
    const setUnselectedValue = (accountOperations: any, permissionsList: any) => {
        accountOperations?.sub?.map((permission: any) => {
            if (permissionsList.sub && !permissionsList?.sub[permission.name]) {
                permissionsList.sub[permission.name] = false
            }
        })
        return permissionsList
    }
    const onGeneralCheck: TreeProps['onCheck'] = (checkedKeys, nodeInfo) => {
        let selectedGeneralList: any = {}
        const checkedPermissions: string[] = []
        if (nodeInfo.checkedNodes.length != 0) {
            selectedGeneralList = getSelectedOperationsList(nodeInfo, checkedPermissions)
            selectedGeneralList = setUnselectedValue(groups.general, selectedGeneralList)
        }
        setCheckedGeneralPermissions(checkedPermissions)
        setSelectedGeneralList(selectedGeneralList)
    }
    const onTasksCheck: TreeProps['onCheck'] = (checkedKeys, nodeInfo) => {
        let selectedTasksList: any = {}
        const selectedGeneralList: any = {}
        const selectedSubList: any = {}
        const checkedPermissions: string[] = []
        setIsBackupTaskSelectedSelected(false)
        if (nodeInfo.checkedNodes.length != 0) {
            selectedTasksList = getSelectedOperationsList(nodeInfo, checkedPermissions)
            if (!(Object.keys(selectedTasksList?.sub).length == 1 && selectedTasksList.sub[DELETEITEMS])) {
                if (!selectedTasksList.sub[RESTORE_AND_DOWNLOAD] && nodeInfo.node.key !== RESTORE_AND_DOWNLOAD) {
                    selectedSubList[RESTORE_AND_DOWNLOAD] = true
                    selectedGeneralList[SUB] = selectedSubList
                    selectedTasksList.sub[RESTORE_AND_DOWNLOAD] = true
                    checkedPermissions.push(RESTORE_AND_DOWNLOAD)
                } else if (!selectedTasksList.sub[RESTORE_AND_DOWNLOAD] && nodeInfo.node.key === RESTORE_AND_DOWNLOAD) {
                    checkedPermissions.length = 0
                    Object.keys(selectedTasksList.sub).forEach(key => {
                        if (![PREVIEW, RESTORE, DOWNLOAD].includes(key)) {
                            selectedTasksList.sub[key] = false
                        }
                    })
                }
            }

            selectedTasksList = setUnselectedValue(groups.tasks, selectedTasksList)
        }
        setCheckedTasksPermissions(checkedPermissions)
        setSelectedTasksList(selectedTasksList)
    }
    const onServicesCheck: TreeProps['onCheck'] = (checkedKeys, nodeInfo) => {
        let selectedServiceList: any = {}
        const checkedPermissions: string[] = []
        setIsServicesSelected(false)
        if (nodeInfo.checkedNodes.length != 0) {
            selectedServiceList = getSelectedOperationsList(nodeInfo, checkedPermissions)

            selectedServiceList = setUnselectedValue(groups.services, selectedServiceList)

            setIsServicesSelected(true)
        }
        setSelectedServiceList(selectedServiceList)
        setCheckedServicesPermissions(checkedPermissions)
    }
    const getSelectedpersmissions = (groupsName: any, checkedPermissions: string[]) => {
        const selectedSubList: any = {}
        const selectedGeneralList: any = {}
        groupsName.sub?.map((nestedData: any) => {
            const generalOperationName = nestedData.name
            selectedSubList[nestedData.name] = true
            checkedPermissions.push(generalOperationName)
            if (
                generalOperationName === PREVIEW ||
                generalOperationName === RESTORE ||
                generalOperationName === DOWNLOAD
            ) {
                setIsBackupTaskSelectedSelected(true)
            }
        })
        selectedGeneralList[SUB] = selectedSubList
        selectedGeneralList[ALL] = true
        return selectedGeneralList
    }
    const onValuesChange = (changedValues: Partial<User>) => {
        const checkedPermissions: string[] = []
        setIsFormTouched(true)

        if (Object.prototype.hasOwnProperty.call(changedValues, 'role')) {
            const newRole = changedValues['role'] as string
            if (newRole == ACCESS_LEVEL.FULL) {
                setIsServicesSelected(true)
                setIsBackupTaskSelectedSelected(true)
                setSelectedData(
                    getSelectedpersmissions(groups.general, checkedPermissions),
                    getSelectedpersmissions(groups.services, checkedPermissions),
                    getSelectedpersmissions(groups.tasks, checkedPermissions)
                )
            } else {
                setSelectedData({}, {}, {})
                setIsServicesSelected(false)
                setIsBackupTaskSelectedSelected(false)
            }
            setSelectedCheckedData(checkedPermissions)
        }
        if (Object.prototype.hasOwnProperty.call(changedValues, 'type')) {
            setIsEmailTypeSelected(true)
            const selectedType = changedValues['type'] as string
            if (selectedType !== CREDENTIALS_TYPES.EMAIL) {
                setIsEmailTypeSelected(false)
            }
        }
    }
    const setSelectedCheckedData = (checkedPermissions: string[]) => {
        setCheckedGeneralPermissions(checkedPermissions)
        setCheckedTasksPermissions(checkedPermissions)
        setCheckedServicesPermissions(checkedPermissions)
    }
    const setSelectedData = (general: any, services: any, tasks: any) => {
        setSelectedGeneralList(general)
        setSelectedServiceList(services)
        setSelectedTasksList(tasks)
    }
    return (
        <Form onFinish={onFinish} onValuesChange={onValuesChange} initialValues={initialValues} form={form}>
            <Jumbotron style={{overflow: 'inherit'}}>
                <UserDetail
                    isPP={false}
                    disableOAuth={disableOAuth}
                    isNew={isNew}
                    isEmail={credentialsData.credentials.type == CREDENTIALS_TYPES.EMAIL}
                    isEmailTypeSelected={isEmailTypeSelected}
                />
            </Jumbotron>
            <Row noWidth={true}>
                <Col>
                    <JumbotronToggle title={t('userManagement:operations')}>
                        <TreeCheckbox
                            isPP={false}
                            operationsList={groups.general}
                            onCheck={onGeneralCheck}
                            checkedPermissions={checkedGeneralPermissions}
                        />
                        <TreeCheckbox
                            isPP={false}
                            operationsList={groups.tasks}
                            onCheck={onTasksCheck}
                            isServicesSelected={isServicesSelected}
                            isBackupTaskSelected={isBackupTaskSelectedSelected}
                            checkedPermissions={checkedTasksPermissions}
                        />
                    </JumbotronToggle>
                </Col>
                <Col>
                    <JumbotronToggle title={t('userManagement:services')}>
                        <TreeCheckbox
                            isPP={false}
                            operationsList={groups.services}
                            onCheck={onServicesCheck}
                            checkedPermissions={checkedServicesPermissions}
                        />
                    </JumbotronToggle>
                </Col>
            </Row>
            <FormFooter>
                {!credentialsData.credentials.isSpecialPartnerAccount && (
                    <Button htmlType="submit" type="primary" loading={submitting} disabled={!isFormTouched}>
                        {t('userManagement:actions:save')}
                    </Button>
                )}

                <Button type="default" disabled={submitting} onClick={history.goBack}>
                    {t('userManagement:actions:cancel')}
                </Button>
            </FormFooter>
        </Form>
    )
}
