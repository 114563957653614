import styled from 'styled-components'
import variables from 'ca-common/variables'

export const StyledBlock = styled.div`
    font-size: ${variables.fontSizeBase};
    font-family: ${variables.mainFont};
    font-weight: 400;
`

export const StyledText = styled.div``

export const StyledInstructions = styled.div`
    padding: ${variables.gutterSm};
    margin-left: ${variables.gutterXs};
    padding-bottom: ${variables.gutterXs};

    ol {
        margin-bottom: 0px;
    }

    ol li ul {
        margin-left: ${variables.gutterSm};
        list-style-type: lower-alpha;
    }

    ol li ul p {
        font-size: ${variables.fontSizeSecondary};
        color: ${variables.ligthBlack};
        margin-bottom: 0px;
        line-height: 1.2;
        margin-left: -15px;
        margin-top: 2px;
    }
`

export const StyledLink = styled.a<{isPartnerPortal: boolean}>`
    color: ${props => (props.isPartnerPortal ? variables.linkBlue : variables.navyBlue)} !important;
    text-decoration: underline !important;
`
