import React, {useState, useEffect} from 'react'
import {Tooltip, Form} from 'antd'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {debounce, isArray} from 'lodash'
import i18n from 'i18next'

import {IconButton} from 'ca-common/ui-lib/molecules/IconButton'
import {TODO_ANY} from 'ca-common/types'
import {getLocalizedOptions} from 'ca-common/utils/filters'

import {MultiSelect} from 'ca-common/ui-lib/atoms/MultiSelect'
import {RangePicker} from 'ca-common/ui-lib/atoms/RangePicker'
import {TreeSelect} from 'ca-common/ui-lib/atoms/TreeSelect'
import {StatusMultiSelect} from 'ca-common/atoms/StatusMultiSelect'
import {SourceIcon} from 'ca-common/components/SourceIcon'
import {SOURCE} from 'ca-common/common/enum'

import {siteFormatter} from 'ca-common/utils/entity'
import {ACTIVITY_NAMES, ACTIVITY_STATUSES} from 'ca-common/common/enum/AccountActivity'
import {Select, Option} from 'ca-common/ui-lib/atoms/Select'
import SearchIcon from 'ca-common/icons/Search.svg'
import ExportIcon from 'ca-common/icons/Export.svg'

import {FieldValues} from 'src/newcore/features/ActivityTable/types/fieldValues'
import {ActivityFiltersProps} from 'src/newcore/features/ActivityTable/organisms/ActivityFilters'
import {SelectedFilters} from 'src/newcore/features/ActivityTable/organisms/SelectedFilters'
import {ROOT_NAMESPACE, ACTIVITY_FIELD_NAMES, ACTIVITY_DEBOUNCE} from 'ca-common/features/ActivityInfinityTable'

import {StyledForm, StyledFormContainer, StyledRow} from './StyledActivityFilterForm'

const {Item: FormItem} = Form
const INITIAL_PARAMS = {
    [ACTIVITY_FIELD_NAMES.STATUSES]: [],
    [ACTIVITY_FIELD_NAMES.TYPE]: [],
    [ACTIVITY_FIELD_NAMES.SERVICE]: [],
    [ACTIVITY_FIELD_NAMES.ENTITIES]: undefined,
    [ACTIVITY_FIELD_NAMES.TASK]: undefined
}

type Task = {
    id: string
    alias: string
    key: string
    source: SOURCE
}

type Entity = {
    entity: string
}

type Params = {
    [key in typeof ACTIVITY_FIELD_NAMES[keyof typeof ACTIVITY_FIELD_NAMES]]: unknown
}

export type ValuesWithMomentDate = FieldValues & {dateRange: moment.Moment[]}

export type ActivityFilterFormProps = ActivityFiltersProps & {
    treeData: TODO_ANY
    changeURLSearchParams: (values: Partial<FieldValues>) => void
}

export const ActivityFilterForm = ({
    submitExport,
    queryParams,
    loading,
    tasks,
    actions,
    taskInfo,
    treeData,
    changeURLSearchParams
}: ActivityFilterFormProps) => {
    const [form] = Form.useForm()
    const {setFieldsValue, getFieldsValue} = form
    const {t} = useTranslation()
    const [taskSelected, setTaskSelected] = useState('')
    const [sources, sourcesSet] = useState<string[]>([] as string[])

    const handleTaskChange = (taskId: string): void => {
        setTaskSelected(taskId)
        if (taskId) {
            actions.getTaskInfo({taskId})
        } else {
            actions.clearTaskInfo()
        }
    }

    function handleFiltersChange(values: FieldValues) {
        const newValues: FieldValues = {...values}
        if (values.entities !== undefined) {
            newValues.externalId = taskInfo.response.find(
                (entity: {entity: string}) => entity.entity === values?.entities
            )?.extid
        } else {
            newValues.externalId = undefined
        }
        changeURLSearchParams(newValues)
    }

    const getEntities = (data: Entity[]) => (data ? [...new Set(data.map(item => item.entity))] : [])
    const getTasks = (data: Task[]) =>
        sources?.length && data ? data.filter(task => sources.includes(task.source)) : data

    const normalizeDate = (params: Params) => {
        let dateRange: moment.Moment[] | undefined = undefined
        if (params.dateRange && isArray(params.dateRange)) {
            dateRange = params.dateRange.map(date => moment(date))
        }

        return {...params, dateRange}
    }

    useEffect(() => {
        if (queryParams === undefined) return
        const {taskId, services} = queryParams
        if (getFieldsValue() !== queryParams) {
            setFieldsValue({
                ...INITIAL_PARAMS,
                ...normalizeDate(queryParams)
            })
        }

        sourcesSet(services?.map((service: string) => (service.includes('__') ? service.split('__')[0] : service)))

        if (taskSelected !== taskId) {
            handleTaskChange(taskId)
        }
    }, [queryParams])

    return (
        <StyledFormContainer>
            <StyledForm layout="inline" form={form} onValuesChange={debounce(handleFiltersChange, ACTIVITY_DEBOUNCE)}>
                <StyledRow>
                    <FormItem name={ACTIVITY_FIELD_NAMES.DATE_RANGE}>
                        <RangePicker disabled={loading} />
                    </FormItem>
                    <FormItem name={ACTIVITY_FIELD_NAMES.TYPE}>
                        <MultiSelect
                            options={getLocalizedOptions(ROOT_NAMESPACE, ACTIVITY_NAMES, ACTIVITY_FIELD_NAMES.TYPE)}
                            buttonText={t('activity:activityFilters:activity')}
                            width={118}
                            popoverWidth={160}
                            borderWidth={2}
                            disabled={loading}
                        />
                    </FormItem>
                    <FormItem name={ACTIVITY_FIELD_NAMES.SERVICE}>
                        <TreeSelect
                            dropdownMatchSelectWidth={250}
                            listHeight={340}
                            virtual={false}
                            treeData={treeData}
                            maxTagPlaceholder={t('activity:activityFilters:service')}
                            placeholder={t('activity:activityFilters:service')}
                            disabled={loading}
                        />
                    </FormItem>
                    <FormItem name={ACTIVITY_FIELD_NAMES.STATUSES}>
                        <StatusMultiSelect
                            options={getLocalizedOptions(
                                ROOT_NAMESPACE,
                                ACTIVITY_STATUSES,
                                ACTIVITY_FIELD_NAMES.STATUSES
                            )}
                            buttonText={t('activity:activityFilters:status')}
                            width={110}
                            popoverWidth={225}
                            borderWidth={2}
                            disabled={loading}
                        />
                    </FormItem>
                </StyledRow>
                <StyledRow>
                    <FormItem name={ACTIVITY_FIELD_NAMES.TASK}>
                        <Select
                            selectWidth={206}
                            filterOption
                            optionFilterProp="title"
                            loading={tasks.status === 'pending'}
                            placeholder={t('activity:activityFilters:searchQueryPlaceholder')}
                            disabled={loading || tasks.status !== 'fulfilled'}
                            prefixIcon={SearchIcon}
                        >
                            {getTasks(tasks.response)?.map((task: Task) => (
                                <Option key={task.id} value={task.id}>
                                    <SourceIcon fontSize={16} source={task.source} />
                                    <Tooltip title={task.alias} placement="right">
                                        {task.alias}
                                    </Tooltip>
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem name={ACTIVITY_FIELD_NAMES.ENTITIES}>
                        <Select
                            selectWidth={180}
                            loading={taskInfo.status === 'pending'}
                            placeholder={t('activity:activityFilters:searchEntityPlaceholder', {
                                amount: taskInfo.response?.length || ''
                            })}
                            disabled={loading || !taskSelected || taskInfo.status !== 'fulfilled'}
                            prefixIcon={SearchIcon}
                        >
                            {getEntities(taskInfo.response as Entity[])?.map(entity => (
                                <Option key={entity} value={entity}>
                                    <Tooltip title={entity} placement="left">
                                        {siteFormatter(entity)}
                                    </Tooltip>
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <IconButton
                        onClick={submitExport(queryParams as FieldValues)}
                        disabled={loading}
                        tooltipText={i18n.t('activity:exportButtonTooltip')}
                        icon={ExportIcon}
                    />
                </StyledRow>
            </StyledForm>
            <SelectedFilters
                tasks={tasks.response}
                setFieldsValue={changeURLSearchParams}
                loading={loading}
                values={getFieldsValue() as ValuesWithMomentDate}
            />
        </StyledFormContainer>
    )
}
