import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Modal} from 'antd'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import toLower from 'lodash/toLower'

import {BACKUP_FREQUENCY} from 'ca-common/utils/datetime'
import {getSvcAccountsDescription} from 'ca-common/utils/text'
import {Button} from 'ca-common/ui-lib/atoms'
import {INTEGRATION_TYPE} from 'src/ca-common/constants'
import {StyledFooter, StyledActions, StyledControl} from '../StyledFooter'

export const MultiEntityFooter = props => {
    const {loading, clearRemovedRulesList, removedRulesList, togglePanel, form, taskSettings} = props
    const {t} = useTranslation()
    const isFieldsTouched = form.isFieldsTouched()
    const userInfo = useSelector(state => state.userInfo.response)
    const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX

    const reset = () => {
        form.resetFields()
        togglePanel()

        clearRemovedRulesList()
    }

    const handleSubmit = () => {
        if (form.getFieldValue('detectNewAccounts') && removedRulesList.length) {
            Modal.confirm({
                title: t('backups:bulkActivation:modal:title', {
                    entityName: toLower(getSvcAccountsDescription(taskSettings.source, true))
                }),
                icon: null,
                content: <Trans i18nKey="backups:bulkActivation:modal:content" />,
                okText: t('modals:buttons:continue'),
                onOk: () => form.submit(),
                width: 620
            })
        } else if (
            taskSettings.isSurchargeNotificationDisplayed &&
            form.isFieldTouched('backupFrequency') &&
            form.getFieldValue('backupFrequency') === BACKUP_FREQUENCY.DAILY3TIMES &&
            !isZix
        ) {
            Modal.confirm({
                title: <Trans i18nKey="backups:bulkActivation:notification:title" />,
                content: `${t('backups:bulkActivation:notification:headerTitle')}  ${t(
                    'backups:bulkActivation:notification:description'
                )}`,
                okText: t('modals:buttons:continue'),
                onOk: () => form.submit(),
                closable: true,
                width: 620
            })
        } else {
            form.submit()
        }
    }

    return (
        <StyledFooter>
            <StyledActions />
            <StyledControl>
                <div>
                    <Button type="link" onClick={reset}>
                        {t('forms:common:actions:cancel')}
                    </Button>
                    <Button
                        type="primary"
                        disabled={!removedRulesList.length && !isFieldsTouched}
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {t('forms:common:actions:save')}
                    </Button>
                </div>
                {(isFieldsTouched || !!removedRulesList.length) && t('forms:backupSettings:fieldChanged')}
            </StyledControl>
        </StyledFooter>
    )
}

MultiEntityFooter.propTypes = {
    clearRemovedRulesList: PropTypes.func.isRequired,
    isFieldsTouched: PropTypes.bool,
    loading: PropTypes.bool
}

MultiEntityFooter.defaultProps = {
    isFieldsTouched: false,
    loading: false,
    removedRulesList: []
}
