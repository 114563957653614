import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {TableColumnsType, Tooltip} from 'antd'

import {formatUTCDateTime} from 'ca-common/utils/datetime'

import {ServiceCell, StatusCell} from 'src/newcore/features/SecurityAudit/atoms'
import type {SecurityAuditItem} from 'src/newcore/features/SecurityAudit/types'

const DATE_WIDTH = 200
const ACTIVITY_WIDTH = 200
const SERVICE_WIDTH = 230
const STATUS_WIDTH = 150
const TASK_WIDTH = 250
const PERFORMED_BY_WIDTH = 200
const DESCRIPTION_WIDTH = 320

export const useColumns = (): TableColumnsType<SecurityAuditItem> => {
    const {t} = useTranslation()
    return useMemo(
        () => [
            {
                title: t('table:columns:date'),
                width: DATE_WIDTH,
                render: (_, {date}) => formatUTCDateTime(date)
            },
            {
                title: t('table:columns:activity'),
                width: ACTIVITY_WIDTH,
                render: (_, {service}) => t(`securityAudit:activity:${service?.toUpperCase()}`)
            },
            {
                title: t('table:columns:service'),
                width: SERVICE_WIDTH,
                render: (_, {source, subSource}) => <ServiceCell source={source} subSource={subSource} />
            },
            {
                title: t('table:columns:status'),
                width: STATUS_WIDTH,
                render: (_, {status, statusDescription, service, description}) => (
                    <StatusCell
                        status={status}
                        statusDescription={statusDescription}
                        service={service}
                        description={description}
                    />
                )
            },
            {
                title: t('table:columns:task'),
                width: TASK_WIDTH,
                ellipsis: {showTitle: false},
                render: (_, {account}) => (
                    <Tooltip placement="topLeft" title={account}>
                        {account}
                    </Tooltip>
                )
            },
            {
                title: t('table:columns:performedBy'),
                width: PERFORMED_BY_WIDTH,
                ellipsis: {showTitle: false},
                render: (_, {performedBy}) => <Tooltip title={performedBy}>{performedBy}</Tooltip>
            },
            {
                title: t('table:columns:description'),
                width: DESCRIPTION_WIDTH,
                ellipsis: {showTitle: false},
                render: (_, {description}) => <Tooltip title={description}>{description}</Tooltip>
            }
        ],
        []
    )
}
