import React from 'react'
import {Tree, Tooltip} from 'antd'
import {TreeProps} from 'antd/es'
import Icon from '@ant-design/icons'
import Info from 'ca-common/icons/Info.svg'
import {RESTORE_AND_DOWNLOAD, DASHBOARD} from 'ca-common/constants'
import {TreeWrapper} from './StyledTreeCheckBox'

type TreeCheckboxprops = {
    isPP: boolean
    operationsList: any
    onCheck: TreeProps['onCheck']
    isServicesSelected?: boolean
    isBackupTaskSelected?: boolean
    checkedPermissions?: any
    enableBackupTask?: boolean
    disableDashboardOptions?: boolean
}

export const TreeCheckbox = (props: TreeCheckboxprops) => {
    const {
        isPP,
        operationsList,
        onCheck,
        isServicesSelected,
        isBackupTaskSelected,
        checkedPermissions,
        enableBackupTask,
        disableDashboardOptions
    } = props
    const disabledTasks = (taskName: string) => {
        return isServicesSelected != undefined
            ? taskName === RESTORE_AND_DOWNLOAD && isBackupTaskSelected
                ? isBackupTaskSelected
                : !isServicesSelected
            : false
    }
    const disabledTasksPP = (taskName: string) => {
        return isServicesSelected != undefined
            ? enableBackupTask != undefined
                ? taskName === RESTORE_AND_DOWNLOAD && isBackupTaskSelected
                    ? isBackupTaskSelected
                    : !enableBackupTask
                : !isServicesSelected
            : taskName === DASHBOARD && disableDashboardOptions
            ? disableDashboardOptions
            : false
    }
    const transformedOperationsList = [
        {
            key: operationsList.name,
            title: operationsList.frendlyName,
            disabled:
                isServicesSelected != undefined
                    ? enableBackupTask != undefined
                        ? !enableBackupTask
                        : !isServicesSelected
                    : false,
            children: operationsList.sub?.map((nestedData: any) => ({
                key: nestedData.name,
                title: nestedData.frendlyName,
                disabled: isPP ? disabledTasksPP(nestedData.name) : disabledTasks(nestedData.name),
                icon: nestedData.infoTooltip != undefined && (
                    <Tooltip title={nestedData.infoTooltip}>
                        <Icon component={Info} />
                    </Tooltip>
                )
            }))
        }
    ]
    return (
        <TreeWrapper>
            <Tree
                showIcon
                defaultExpandAll
                checkable
                treeData={transformedOperationsList}
                onCheck={onCheck}
                checkedKeys={checkedPermissions}
            />
        </TreeWrapper>
    )
}
