import React from 'react'
import {AnyAction, bindActionCreators, Dispatch} from '@reduxjs/toolkit'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {CABaseHeader} from 'ca-common/features/BaseHeader'
import {renderTo} from 'ca-common/utils/render'
import {
    INTEGRATION_TYPE,
    PAGES,
    USER_ACCOUNT_TYPE,
    PORTAL_BASENAME,
    CREDIT_CARD_STATUS,
    PAYMENT_TYPE
} from 'ca-common/constants'
import {AlertProps} from 'ca-common/ui-lib/atoms/Alert'
import {getLocalDateFromUTC, LONG_DATE_FORMAT} from 'ca-common/utils/datetime'
import {validatePageAccess} from 'ca-common/utils/permissions'
import type {CompanyInfoType, UserInfoDataType} from 'ca-common/types'
import {TrialMessage} from 'ca-common/features/BaseHeader/organisms/TrialMessage'
import {hideBillsState, signOut as signOutAction, userInfoSelector} from 'src/newcore/redux/modules/account'
import {useAppSelector, AppState} from 'src/newcore/components/Root'

type CAHeaderProps = {
    companyInfo: CompanyInfoType
    actions: {signOut: any; hideBillsState: () => void}
    userInfo: UserInfoDataType
}

export const CAHeader = ({companyInfo, actions: {signOut, hideBillsState}, userInfo}: CAHeaderProps) => {
    const {t} = useTranslation()
    const isZix = userInfo?.integrationType === INTEGRATION_TYPE.ZIX
    const isPrimary = userInfo?.userAccountType === USER_ACCOUNT_TYPE.PRIMARY
    let completedNotification: AlertProps | null = null
    const paymentMethod = useAppSelector((state: AppState) => state.billing?.paymentMethod)
    if (userInfo?.permissions?.groups?.PAGE.BILLING_STATE?.permissions.ACCESS.enabled) {
        if (userInfo.reminder) {
            completedNotification = {
                ...userInfo.reminder,
                updated: getLocalDateFromUTC(userInfo.reminder.updated, LONG_DATE_FORMAT, 'MMM DD, YYYY'),
                tooltipMessage: t('header:billingAlertTooltip'),
                onClose: hideBillsState
            }
            if (validatePageAccess({page: PAGES.BILLING, userInfo})) {
                completedNotification.link = {
                    name: t('header:billingAlertLinkTitle'),
                    url: `/${PAGES.BILLING}/status`
                }
            }
        }
        if (paymentMethod?.response?.cardStatus === CREDIT_CARD_STATUS.FAILED) {
            completedNotification = {
                title: t('header:billingAlert:paymentFailed'),
                type: 'ERROR',
                message: t('header:billingAlert:paymentFailedMessage'),
                afterLinkMessage: t('header:billingAlert:paymentFailedText'),
                isPaymentError: true
            }
            if (validatePageAccess({page: PAGES.BILLING, userInfo})) {
                completedNotification.link = {
                    name: t('billing:paymentDetails:title'),
                    url: `/${PAGES.BILLING}/${
                        paymentMethod.response?.subscriptionType === PAYMENT_TYPE.ANNUAL
                            ? PAGES.ANNUAL_SUBSCRIPTION
                            : PAGES.PAYMENTS_DETAILS
                    }`
                }
            }
        }
        if (paymentMethod?.response?.cardStatus === CREDIT_CARD_STATUS.VERIFICATION) {
            completedNotification = {
                title: t('header:billingAlert:paymentProcessing'),
                type: 'INFO',
                message: t('header:billingAlert:paymentProcessingMesssage'),
                isPaymentError: true
            }
        }
    }

    return (
        <CABaseHeader
            logo={{
                url: companyInfo.smallLogo || companyInfo.logo,
                redirectUrl: `/${PORTAL_BASENAME}`,
                name: companyInfo.name
            }}
            companyInfo={companyInfo}
            isZix={isZix}
            isWL={companyInfo.whiteLable || companyInfo.isWhiteLable}
            isPrimary={isPrimary}
            // fix till oldapp has single root
            trialMessage={<TrialMessage userInfo={userInfo} companyInfo={companyInfo} />}
            userName={userInfo.name}
            userEmail={userInfo.email}
            isFinra={userInfo.isFinra}
            isMsEndUser={userInfo.isMsEndUser}
            applicationHeader={companyInfo?.applicationHeader}
            settingsUrl={`/${PORTAL_BASENAME}/${PAGES.SETTINGS}/${PAGES.ACCOUNT}`}
            signOutFunction={signOut}
            notification={completedNotification}
            title={
                isZix
                    ? companyInfo?.applicationHeader != undefined
                        ? companyInfo?.applicationHeader
                        : ''
                    : t('header:portalTitle')
            }
            titleVersion={
                isZix
                    ? companyInfo?.applicationHeader != undefined
                        ? t('header:portalTitleVersion')
                        : ''
                    : t('header:portalTitleVersion')
            }
        />
    )
}

function mapStateToProps(state: {userInfo: UserInfoDataType}) {
    return {
        userInfo: state.userInfo
    }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        actions: bindActionCreators(
            {
                signOut: signOutAction,
                hideBillsState
            },
            dispatch
        )
    }
}

function createHeader() {
    return connect(mapStateToProps, mapDispatchToProps)(CAHeader)
}

export function renderHeaderTo(domElement: HTMLElement, store: any, props: any) {
    renderTo(domElement, createHeader(), store, props)
}

export default createHeader()
