import styled, {keyframes} from 'styled-components'

import variables from 'ca-common/variables'

export const LogoWrapper = styled.div`
    width: 125px;
    min-width: 125px;
    height: 90px;

    padding: ${variables.gutterXs};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    img {
        width: 137px;
        height: 34.32px;
        max-height: 100%;
        object-fit: none;
    }
`

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%)
  }
`

export const StyledCustomers = styled.div`
    background: ${variables.white};
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-shadow: 0px 0px 15px 0px #00000026;
    padding: 12px 0;
`

export const CustomersWrapper = styled.div`
    animation: ${scrollAnimation} 20s linear infinite;
    display: flex;
    width: calc(165px * 9);
`
