import styled from 'styled-components'
import {Layout} from 'antd'
import {headerGradient} from 'ca-common/features/BaseHeader/lib/constants'
import variables from 'ca-common/variables'
import {Button} from 'ca-common/ui-lib/atoms/Button'
import {CALink} from 'ca-common/ui-lib/atoms/CALink'
import Logo from 'ca-common/icons/Logo.svg'

const {Header} = Layout

export const PublicHeaderWrapper = styled(Header)`
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    line-height: normal;
    padding: ${variables.gutterSm};
    background: ${headerGradient};
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${variables.white};

    @media (min-width: ${variables.screenSm}) {
        height: 100px;
    }
`

export const PublicHeaderLogo = styled.div`
    transition: none;
    width: clamp(128px, 20vw, 155px);

    a {
        display: flex;
        padding: ${variables.gutterMd} 2px ${variables.gutterMd} ${variables.gutterSm};
    }

    &:hover {
        opacity: 0.8;
    }
`

export const PublicHeaderRightMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`

export const LogoIcon = styled.img`
    width: 137px;
    height: 34.32px;
    max-width: 100%;
`
export const CALogo = styled(Logo)`
    width: 137px;
    height: 34.32px;

    @media (max-width: ${variables.screenSm}) {
        width: 200px;
    }
`

export const PublicHeaderBackButton = styled(Button)`
    color: ${variables.white};
    padding: 0;
    display: flex;
    border-radius: 0;
    align-items: center;
    justify-content: center;
    font-family: ${variables.publicPageFont};
    font-weight: 400;
    font-size: ${variables.fontSizeBase};

    &:hover *,
    &:focus * {
        color: ${variables.iceBlue};
    }

    &:active * {
        color: ${variables.brightBlue};
    }

    .anticon {
        line-height: 0;
    }
`

export const PublicHeaderButton = styled(Button)`
    margin-left: ${variables.gutterXs};
    font-family: ${variables.publicPageFont};
    font-size: ${variables.fontSizeBase};
    font-weight: 700;
`

export const PublicHeaderButtonLink = styled(CALink)`
    padding: 0;
    margin-left: ${variables.gutterXs};

    @media (max-width: ${variables.screenSm}) {
        margin-left: 4px;
    }
`

export const MobileButton = styled(Button)`
    display: none;
    @media (max-width: ${variables.screenSm}) {
        display: block;
    }
`

export const MobileMenuWrapper = styled.div`
    background: ${variables.brandDark};
    padding: ${variables.gutterLg};
`

export const MobileMenu = styled.div<{visible: boolean}>`
    display: none;
    position: absolute;
    top: 100%;
    background: white;
    left: 0;
    right: 0;
    @media (max-width: ${variables.screenSm}) {
        ${props => props.visible && 'display: block;'}
    }
`

export const UnderLayer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 100vh;
    background: ${variables.black};
    opacity: 0.5;
`

export const DesktopMenuWrapper = styled.div`
    @media (max-width: ${variables.screenSm}) {
        display: none;
    }
`

export const StyledHeaderSeparator = styled.div`
    background-color: ${variables.navyBorder};
    width: 1px;
    height: ${variables.gutterMd};
    margin: 0 ${variables.gutterXs};

    @media screen and (min-width: ${variables.screenLg}) {
        margin: 0 ${variables.gutterSm} 0 ${variables.gutterXs};
    }
`

export const StyledHeaderTitle = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-self: flex-start;
    p {
        font-family: ${variables.publicPageFont};
        color: ${variables.white};
        margin: 0;
        display: ruby;
    }
`

export const StyledTitleAndVersion = styled.div<{isTitleVersion?: boolean}>`
    font-family: ${variables.publicPageFont};
    font-size: ${props => (props.isTitleVersion ? '10px' : '19px')};
    color: ${variables.white};
    font-weight: 400;
    display: flex;
    line-height: 1;
    white-space: nowrap;
    position: relative;
    margin-left: ${props => (props.isTitleVersion ? variables.gutterXs : '0px')};
    margin-top: ${props => (props.isTitleVersion ? '7px' : '0px')};

    @media screen and (min-width: ${variables.screenMd}) {
        font-size: ${props => (props.isTitleVersion ? variables.fontSizeSecondary : '21px')};
    }
`
