import styled, {css} from 'styled-components'
import {Table} from 'antd'

import {TODO_ANY} from 'ca-common/types'
import variables from 'ca-common/variables'

export const StyledTable = styled(Table)<TODO_ANY>`
    margin-top: ${variables.gutterSm};

    .ant-table-thead
        tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        content: none;
    }

    .ant-table-tbody > tr > td {
        border-bottom: none;
    }
`

export const TableWrapper = styled.div<{disabled: boolean}>`
    ${props =>
        props.disabled &&
        css`
            opacity: 0.6;
        `}
`

export const StyledEmpty = styled.div`
    color: ${variables.DarkGrey};
    margin: ${variables.gutterXs} auto;
    font-family: ${variables.mainFont};
    font-style: normal;
    font-weight: 400;
    font-size: ${variables.fontSizeBase};
    line-height: 140%;
`
