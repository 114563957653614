import React from 'react'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import Icon from '@ant-design/icons'

import HeaderArrowLeft from 'ca-common/icons/HeaderArrowLeft.svg'
import Account from 'ca-common/icons/AccountDark.svg'

import {CLOUDALLY_URL} from 'ca-common/constants'
import {
    PublicHeaderBackButton,
    PublicHeaderButtonLink,
    StyledHeaderTitle,
    StyledHeaderSeparator,
    StyledTitleAndVersion
} from 'ca-common/components/PublicSite/Header/StyledHeader'

import {CALogo, LoginBtn, LogoIcon, PublicHeaderLogo, StyledButtonsWrapper, StyledHeader} from './StyledHeader'

export type HeaderProps = {
    logoSrc?: string
    name?: string
    isExternalUserManagement?: boolean
    title: string
    titleVersion?: string
    isWL?: boolean
}

export const Header = ({logoSrc, name, isExternalUserManagement, title, titleVersion, isWL}: HeaderProps) => {
    const url = isExternalUserManagement ? '' : '/'
    const history = useHistory()
    const {t} = useTranslation()

    return (
        <StyledHeader>
            <StyledHeaderTitle>
                <PublicHeaderLogo>
                    <a href={url}>{logoSrc ? <LogoIcon src={logoSrc} alt={name} /> : <CALogo alt="Cloudally" />}</a>
                </PublicHeaderLogo>
                {!isWL && (
                    <>
                        <StyledHeaderSeparator />
                        <StyledTitleAndVersion>{title}</StyledTitleAndVersion>
                        <StyledTitleAndVersion isTitleVersion={true}>{titleVersion}</StyledTitleAndVersion>
                    </>
                )}
            </StyledHeaderTitle>
            <StyledButtonsWrapper>
                <PublicHeaderButtonLink pureLink to={CLOUDALLY_URL}>
                    <PublicHeaderBackButton size="large" type="link">
                        <Icon component={HeaderArrowLeft} />
                        {t('header:menu:home')}
                    </PublicHeaderBackButton>
                </PublicHeaderButtonLink>
                <PublicHeaderButtonLink
                    to={{
                        pathname: '/sign-in',
                        search: history.location.search
                    }}
                >
                    <LoginBtn size="large" type="primary">
                        <Icon component={Account} />
                        {t('header:menu:login')}
                    </LoginBtn>
                </PublicHeaderButtonLink>
            </StyledButtonsWrapper>
        </StyledHeader>
    )
}
