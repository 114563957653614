import React from 'react'
import styled, {css} from 'styled-components'
import {transparentize} from 'polished'
import {Link} from 'react-router-dom'
import {Button, Tooltip, TooltipProps} from 'antd'
import {WarningFilled, ExclamationCircleFilled, InfoCircleFilled} from '@ant-design/icons'
import variables from 'ca-common/variables'
import InfoIcon from 'ca-common/icons/PaymentDetails/InfoIcon.svg'
import type {AlertType} from './Alert'

export const StyledAlert = styled.aside<{type: AlertType; isPaymentError: boolean}>`
    display: flex;
    align-items: center;
    padding: ${variables.gutterXs} ${variables.gutterSm};
    background-color: ${variables.white};
    @media screen and (max-width: ${variables.screenXl}) {
        width: ${props => (props.isPaymentError ? '379px' : '')};
        font-size: ${props =>
            props.isPaymentError ? (props.type === 'ERROR' ? '9px' : '13px') : `${variables.gutterSm}`};
    }
    @media screen and (max-width: 1240px) and (min-width: ${variables.screenXl}) {
        width: ${props => (props.isPaymentError ? '441px' : '')};
        font-size: ${props =>
            props.isPaymentError ? (props.type === 'ERROR' ? '9px' : '13px') : `${variables.gutterSm}`};
    }
    @media screen and (max-width: ${variables.screenLaptop}) and (min-width: 1240px) {
        width: ${props => (props.isPaymentError ? (props.type === 'ERROR' ? '519px' : '502px') : '')};
        font-size: ${props =>
            props.isPaymentError
                ? props.type === 'ERROR'
                    ? '11px'
                    : `${variables.fontSizeSecondary}`
                : `${variables.gutterSm}`};
    }
    @media screen and (max-width: 1520px) and (min-width: ${variables.screenLaptop}) {
        width: ${props => (props.isPaymentError ? (props.type === 'ERROR' ? '600px' : '573px') : '')};
        font-size: ${props =>
            props.isPaymentError
                ? props.type === 'ERROR'
                    ? '13px'
                    : `${variables.fontSizeBase}`
                : `${variables.gutterSm}`};
    }

    border: 1px solid
        ${props =>
            props.type === 'ERROR' ? variables.red : props.type === 'WARNING' ? variables.orange : variables.navyBlue};
    border-radius: ${variables.borderRadiusBase};
    font-size: ${props => (props.isPaymentError ? '15px' : `${variables.fontSizeBase}`)};
    font-family: ${variables.publicPageFont};
    margin-right: ${props => (props.isPaymentError ? (props.type === 'ERROR' ? '4%' : '14%') : '')};
    &,
    a {
        color: ${props => (props.type === 'ERROR' || props.type === 'INFO' ? variables.black : variables.navyBlue)};
    }

    svg {
        ${props =>
            props.type === 'WARNING' &&
            css`
                fill: ${variables.orange};
            `}
    }

    button {
        color: ${transparentize(0.45, variables.black)};
    }
`

export const StyledTitle = styled.h4<{type: AlertType; isPaymentError: boolean}>`
    font-weight: 500;
    margin: 0 0.2em 0 0;
    font-family: ${variables.publicPageFont};
    color: ${props =>
        props.type === 'ERROR'
            ? props.isPaymentError
                ? variables.red
                : variables.black
            : props.type === 'WARNING'
            ? variables.orange
            : variables.navyBlue};
`

export const StyledMessage = styled.span`
    font-weight: 300;
    margin-right: 4px;
`
export const StyledAfterLinkMessage = styled.span`
    font-weight: 300;
    margin-left: 6px;
`
export const StyledLinkContainer = styled.span<{isPaymentError: boolean}>`
    font-weight: ${props => (props.isPaymentError ? 300 : 400)};
    text-decoration: ${props => (props.isPaymentError ? 'underline' : '')};
`

export const StyledLink = styled(Link)`
    text-decoration: underline;
    cursor: pointer;
`

export const StyledRightContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 22px;
    padding-left: ${variables.gutterSm};
    gap: 4px;
`

export const StyledDate = styled.span`
    font-size: 11px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0;

    color: ${variables.text};
`

export const StyledAlertButton = styled(Button)`
    margin-right: -10px;
`

export const StyledWarningIcon = styled(WarningFilled)`
    color: ${variables.red};
    font-size: ${variables.gutterSm};
    margin-right: ${variables.gutterXs};
`

export const StyledReminderIcon = styled(ExclamationCircleFilled)`
    color: ${variables.navyBlue};
    font-size: 16px;
    margin-right: ${variables.gutterXs};
`
export const StyledInfoIcon = styled(InfoIcon)`
    color: ${variables.navyBlue};
    font-size: 16px;
    margin-right: ${variables.gutterXs};
`

export const StyledTooltip = (props: JSX.IntrinsicAttributes & (TooltipProps & React.RefAttributes<unknown>)) => {
    const innerStyles: React.CSSProperties = {
        fontWeight: 300,
        fontFamily: variables.publicPageFont
    }
    return <Tooltip overlayInnerStyle={innerStyles} {...props} />
}

export const StyledTooltipIcon = styled(InfoCircleFilled)`
    color: ${variables.asphaltLight};
    font-size: 16px;
`
