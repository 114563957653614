import {getSourceName} from 'ca-common/utils/sources'
import {ACTIVITY_STATUS} from 'ca-common/common/enum'
import {MS365SubSource} from 'ca-common/types'

import {EVENT_NAMES, track, getMixpanelStorageName} from 'src/newcore/utils/mixpanel'
import {CHECK_STATE} from 'src/newcore/organisms/CustomTree'

import type {ConfirmationProps} from 'src/newcore/features/RecoveryWizard/templates/Confirmation'
import {getRestoreTypeMixpanel} from 'src/newcore/features/RecoveryWizard/lib'

import {EMAIL_SETTINGS} from '.'

export const sendSelectiveRestore = (props: Pick<ConfirmationProps, 'wizardData'>) => {
    const {
        wizardData: {
            entity,
            targetAccount,
            originSubsources,
            subServices,
            selectiveRecovery,
            isPartialSelectedDate,
            restoreOptions,
            jobLabel
        }
    } = props

    track(EVENT_NAMES.EXCHANGE_SELECTIVE_RESTORE, {
        'Sub Services Backup Succeeded': originSubsources
            ? originSubsources.filter(i => i.status === ACTIVITY_STATUS.SUCCEED).map(j => getSourceName(j.source))
            : [],
        'Sub Services Backup Failed': originSubsources
            ? originSubsources.filter(i => i.status === ACTIVITY_STATUS.FAILED).map(j => getSourceName(j.source))
            : [],
        'Sub Services Included': Object.entries(subServices)
            .filter(([, value]) => value)
            .map(([key]) => getSourceName(key)),
        'Sub Services Full Recovery': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return subServices[k as MS365SubSource] && value?.checkAll === CHECK_STATE.CHECKED
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Sub Services Only Folders Selected': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return (
                              subServices[k as MS365SubSource] &&
                              value?.checkAll === CHECK_STATE.PARTIAL &&
                              value.checkedFolders.checked.length > 0 &&
                              !value.itemsInfo
                          )
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Sub Services Only Items Selected': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return (
                              subServices[k as MS365SubSource] &&
                              value?.checkAll === CHECK_STATE.PARTIAL &&
                              value.checkedFolders.checked.length < 1 &&
                              value.itemsInfo &&
                              Object.values(value.itemsInfo).length > 0
                          )
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Backup Status': isPartialSelectedDate ? 'Partially Succeeded' : 'Succeeded',
        'Restore Destination': entity.entity !== targetAccount ? 'Another Account' : 'Original Account',
        'Restore Type': getRestoreTypeMixpanel(restoreOptions.restoreType),
        'Has Job Label': jobLabel?.length > 0 ? true : false
    })
}

export const sendSelectiveExport = (props: Pick<ConfirmationProps, 'wizardData'>) => {
    const {
        wizardData: {
            originSubsources,
            format,
            subServices,
            selectiveRecovery,
            storageType,
            emailSettings,
            isPartialSelectedDate,
            jobLabel
        }
    } = props

    track(EVENT_NAMES.EXCHANGE_SELECTIVE_EXPORT, {
        'Sub Services Backup Succeeded': originSubsources
            ? originSubsources.filter(i => i.status === ACTIVITY_STATUS.SUCCEED).map(j => getSourceName(j.source))
            : [],
        'Sub Services Backup Failed': originSubsources
            ? originSubsources.filter(i => i.status === ACTIVITY_STATUS.FAILED).map(j => getSourceName(j.source))
            : [],
        'Sub Services Included': Object.entries(subServices)
            .filter(([, value]) => value)
            .map(([key]) => getSourceName(key)),
        'Sub Services Full Recovery': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return subServices[k as MS365SubSource] && value?.checkAll === CHECK_STATE.CHECKED
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Sub Services Only Folders Selected': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return (
                              subServices[k as MS365SubSource] &&
                              value?.checkAll === CHECK_STATE.PARTIAL &&
                              value.checkedFolders.checked.length > 0 &&
                              !value.itemsInfo
                          )
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Sub Services Only Items Selected': selectiveRecovery
            ? Object.entries(selectiveRecovery)
                  .filter(([k, value]) => {
                      if (typeof value !== 'string') {
                          return (
                              subServices[k as MS365SubSource] &&
                              value?.checkAll === CHECK_STATE.PARTIAL &&
                              value.checkedFolders.checked.length < 1 &&
                              value.itemsInfo &&
                              Object.values(value.itemsInfo).length > 0
                          )
                      }

                      return false
                  })
                  .map(([key]) => getSourceName(key))
            : [],
        'Backup Status': isPartialSelectedDate ? 'Partially Succeeded' : 'Succeeded',
        'Export Destination': getMixpanelStorageName(storageType),
        'Export Format': format,
        'Emails Configuration': Object.entries(emailSettings)
            .filter(([, v]) => v)
            .map(([k]) => {
                switch (k) {
                    case EMAIL_SETTINGS.includeDeleted:
                        return 'All Deleted Items'
                    case EMAIL_SETTINGS.includeLegalHold:
                        return 'Legal Holds'
                    case EMAIL_SETTINGS.preferSinglePST:
                        return 'Use 20GB Chunks'

                    default:
                        return ''
                }
            }),
        'Has Job Label': jobLabel?.length > 0 ? true : false
    })
}
