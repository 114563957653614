import React, {useEffect} from 'react'
import {bindActionCreators} from '@reduxjs/toolkit'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {matchPath} from 'react-router'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import values from 'lodash/values'
import {useTranslation} from 'react-i18next'

import {Spinner} from 'ca-common/ui-lib/atoms/Spinner'
import {Container} from 'ca-common/components/PublicSite/Container'
import {isExternal} from 'src/newcore/utils/companyInfo'
import {getPublicCompanyInfo} from 'src/newcore/redux/modules/companyInfo'
import {APP_DEFAULT_PAGE, PUBLIC_PAGES} from 'ca-common/constants'

const ContainerRaw = props => {
    const isCloudally = get(props, 'companyInfo.response.isCloudally')
    const {t} = useTranslation()

    useEffect(() => {
        const match = matchPath(props.history.location.pathname, {
            path: values(PUBLIC_PAGES)
        })

        if (!isEmpty(props.userInfo.response) && match) {
            props.history.push(`/${APP_DEFAULT_PAGE}`)
        }
    }, [])

    useEffect(() => {
        if (props.companyInfo.status !== 'fulfilled') {
            props.actions.getPublicCompanyInfo()
        }
    }, [])

    return props.companyInfo.status === 'fulfilled' ? (
        <Container
            marketing={props.marketing}
            type={props.type}
            logoSrc={get(props, 'companyInfo.response.logo280x51')}
            name={get(props, 'companyInfo.response.name')}
            isExternalUserManagement={isExternal()}
            children={props.children}
            toCloudallyUrl={props.toCloudallyUrl && isCloudally && get(props, 'companyInfo.response.url')}
            isCloudally={isCloudally}
            showSignUp
            showMenu={props.showMenu}
            isLogout={props.isLogout}
            title={t('header:portalTitle')}
            titleVersion={t('header:portalTitleVersion')}
        />
    ) : (
        <Spinner modifier="page" />
    )
}

const mapStateToProps = state => ({
    companyInfo: state.companyInfo,
    userInfo: state.userInfo
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getPublicCompanyInfo
        },
        dispatch
    )
})

export const PublicContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ContainerRaw))

PublicContainer.propTypes = {
    isLogout: PropTypes.bool
}

PublicContainer.defaultProps = {
    isLogout: false
}
